import tw from 'twin.macro';

const Container = tw.div`
font-body
text-base
text-black-light
text-blue-dark
`;

export default Container;
